export type WebsuiteCalls = (typeof WebsuiteCalls)[number];
export const WebsuiteCalls = [
  "projects",
  "getProjectSettings",
  "allStaticData",
  "allDynamicData",
  "allStaticDataVessel",
  "allDynamicDataVessel",
  "getPatterns",
  "getProducts",
  "getComplaintData",
  "getDcmrComplaintData",
  "getMaintenance",
  "getMobileOdourDetectionData",
  "getComplaintUpdate",
  "setComplaintUpdate",
  "getGaussianDispersionModel",
] as const;
